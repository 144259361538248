import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import CaseHeader from 'components/work/CaseHeader'
import ContentSection from 'components/work/ContentSection'
import WorkShowcase from 'components/work/WorkShowcase'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import Section from 'components/Section'
import Testimonial from 'components/Testimonial'
import Pill from 'components/Pill'
import PillGroup from 'components/Pill/PillGroup'
import Related from 'components/industries/Related'

const projectHandle = 'vape'

const Vape = () => (
  <>
    <MetaTags title="Online store for global vape brand | Custom software development in Montreal | Volume7" />

    <CaseHeader
      breadcrumbs={[
        {
          label: 'Work',
          link: '/work/',
        },
        {
          label: 'Vape',
        },
      ]}
      heading={INFO_FOR_PROJECT_NAME[projectHandle].heading}
      industry={INFO_FOR_PROJECT_NAME[projectHandle].industry}
      year={INFO_FOR_PROJECT_NAME[projectHandle].year}
      figure={INFO_FOR_PROJECT_NAME[projectHandle].figure}
      isAnonymized
    />

    <ContentSection
      title="The challenge"
      content={
        <>
          <p>
            One of the world's largest tobacco companies was looking for a
            technical partner to build a direct-to-consumer experience for their
            new vaping brand, at the time only sold through point of sale at
            third-party retailers.
          </p>
          <p>
            Volume7 was chosen to lead that initiative because of our broad
            skill set, with specific experience delivering e-commerce solutions.
            Over the course of 2 years, we led the development efforts in
            creating a best-in-class shopping experience, as well as developing
            the supporting infrastructure for scale and geographic expansion.
          </p>
        </>
      }
    />

    <ContentSection
      title="Our solution"
      content={
        <>
          <p>
            We worked with the leadership team to identify customer requirements
            and frequent pain points, and established a roadmap broken down by
            feature sprints. Initially focused on the online shopping experience
            itself, we quickly ramped up to building the supporting
            infrastructure: inventory management, discounts, fulfillment,
            testing, etc. As the project increased in scope and complexity, we
            gradually scaled our allocated resources to meet those changing
            needs.
          </p>

          <ul>
            <li>
              Feature-packed online store powered by Shopify
              <ul>
                <li>Subscriptions</li>
                <li>Product personalization with visual editor</li>
                <li>Tiered loyalty program</li>
                <li>
                  Automated discounts, exclusive access, and early releases
                </li>
                <li>Referrals with store credits</li>
                <li>Blog</li>
              </ul>
            </li>

            <li>Retail store locator with geolocation</li>
            <li>In-store/curb-side pickup (Click and collect)</li>
            <li>
              Real-time inventory management with locale-specific products
            </li>
            <li>Bi-directional fulfillment network with carriers</li>
            <li>No-code product management console</li>
            <li>Fully automated end-to-end testing pipeline</li>
            <li>Logging, alerting, and monitoring setup</li>
          </ul>
          <PillGroup>
            <Pill label="Project management" link="/services/strategy/" />
            <Pill label="Analytics" link="/services/analytics/" />
            <Pill
              label="User experience (UX) design"
              link="/services/design/"
            />
            <Pill label="User interface (UI) design" link="/services/design/" />
            <Pill label="Web development" link="/services/development/" />
            <Pill label="Backend development" link="/services/development/" />
            <Pill label="Automated testing" link="/services/development/" />
            <Pill label="Security" link="/services/development/" />
            <Pill label="Cloud migration" link="/services/development/" />
          </PillGroup>
        </>
      }
    />

    <Section flushTop>
      <Wrapper>
        <WorkShowcase
          photos={[
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/vape/shop.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Collection, product, cart, profile pages',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/vape/bundle.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Promotions system including product bundling',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/vape/rewards.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption:
                'Rewards system with exclusive access and discount codes',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/vape/store-locator.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Retail store locator with geolocation',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/vape/design-system.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              ),
              large: true,
              caption: 'Web design system',
            },
          ]}
        />
      </Wrapper>
    </Section>

    <ContentSection
      title="The results"
      content={
        <>
          <p>
            The client enhanced its brand recognition with a fresh and
            consistent user experience across all consumer touchpoints, and is
            now equipped with a suite of services built to streamline their
            supply chain allowing for scale and efficiency. They've also
            significantly increased their market value share and have built a
            loyal customer base that's rapidly growing.
          </p>
        </>
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <Testimonial
          role="Head of Digital - Canada"
          quote="Volume7 has proven to be an invaluable partner as we've scaled our digital initiatives, providing us with their deep and broad technical expertise, as well as their ability to quickly adapt to our evolving needs."
          alignment="left"
          isAnonymized
        />
      </Wrapper>
    </Section>
    <Related projects={['usewalter', 'aldo']} hasBorder />
  </>
)

export default Vape
